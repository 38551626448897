<template>
  <div class="userOperations">
    <div class="leftUserOperation">
      <div class="operationOptions" @click="$parent.favoriteGoodsAdd()">
        <img
          v-if="$parent.goodsFavoriteStatus"
          :src="require('@/assets/icon/shoucang1.png')"
          alt=""
        />
        <img v-else :src="require('@/assets/icon/shoucang2.png')" alt="" />
        <span>{{ $fanyi("收藏") }}</span>
      </div>
      <div class="operationOptions" @click="openChat">
        <img :src="require('@/assets/icon/rakuChat.png')" alt="" />
        <span>{{ $fanyi("联系担当") }}</span>
      </div>
    </div>

    <button @click="$parent.addToShoppingCart">
      {{ $fanyi("加入购物车") }}
    </button>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted, computed } from "vue";
const { proxy } = getCurrentInstance();
//----------------------------computed----------------------
const theTotalPrice = computed(() => {
  return proxy.$parent.theTotalPrice || 0;
});
// 打开聊天框
const openChat = () => {
  // 判断是否登录,没有登录就打开登录弹窗
  // if (!proxy.$store.state.userInfo) {
  //   return proxy.$store.commit(
  //     "changeshowLoginDia",
  //     !proxy.$store.state.showLoginDia
  //   );
  // }

  // <div style="display:flex">
  //       <img src="${
  //         proxy.$parent.theMainFigure
  //       }" style="width:200px;height:200px"/>
  //       <a href="${encodeURIComponent(
  //         location.protocol + "//" + location.host + proxy.$route.fullPath
  //       )}" target="_blank">${proxy.$parent.details.titleT}</a>
  //       </div>
  proxy.$parent.loading = true;
  // 打开聊天弹窗
  proxy.$api
    .sendTimMessage({
      message: `
     <a href="${encodeURIComponent(
       location.protocol + "//" + location.host + proxy.$route.fullPath
     )}" target="_blank">${proxy.$parent.details.titleT}</a>
      `,
    })
    .then((res) => {
      proxy.$parent.loading = false;
      ////console.log('事件名',res)
      if (res.code != 0) return this.$message.error(this.$fanyi(res.data.msg));
      //接下来的操作
      proxy.$store.commit("changeshowChat", !proxy.$store.state.showChat);
    });
};
// 购买数量
const quantityPurchased = computed(() => {
  return proxy.$parent.quantityPurchased;
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.userOperations {
  min-height: 100px;
  background-color: white;
  display: flex;
  padding: 15px 30px;
  justify-content: space-between;
  border-top: 1px solid #dfdfdf;
  border-left: none;
  border-right: none;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.03);
  // position: fixed;
  // bottom: -1px;
  width: 100%;

  .leftUserOperation {
    display: flex;
    justify-content: space-around;
    flex: 1;
    .operationOptions {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      img {
        width: 34px;
        height: 34px;
      }
      span {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: #000000;
      }
    }
  }

  button {
    margin-left: 10px;
    min-width: 390px;
    height: 73px;
    padding: 0 10px;
    background: #b4272b;
    border: 1px solid #b4272b;
    border-radius: 6px;
    font-size: 28px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    #addCart {
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
    }
  }
}
</style>
