<!-- 找不到商品 -->
<template>
  <headgoback class="headGundong">
    <div class="headIcon">
      <img
        @click="$fun.routerToPage('cart')"
        class="cartIcon"
        :src="require('@/assets/icon/gouwuche.png')"
        alt=""
      />
    </div>
  </headgoback>
  <div class="noData" v-if="$parent.loading == false">
    <div>
      <img :src="require('@/assets/icon/sp.png')" alt="" />
      <span>{{
        $fanyi("当前商品已失效或已下架，无法继续购买，请查看其他商品。")
      }}</span>
    </div>
  </div>
</template>
<script>
import headgoback from "@/components/headGoBack/index.vue";
import { getCurrentInstance } from "vue";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();
    return {};
  },
  data() {
    return {};
  },
  components: { headgoback },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
:deep().headGundong {
  position: fixed;
  top: 0;
}
.headIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .addToFavorites {
    margin-right: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 35px;
      height: 33px;
    }
  }
  .cartIcon {
    width: 30px;
    height: 30px;
  }
}
.noData {
  padding-top: 262px;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 294px;
      height: 280px;
      margin-bottom: 42px;
    }
    span {
      display: block;
      width: 460px;
      text-align: center;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
