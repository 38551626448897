<template>
  <div>
    <goodsOptBox :doNotOpen="false">
      <template #modelName>
        <div class="moduleTitle">
          <span class="name">{{ $fanyi("原url来源") }}</span>
          <span
            class="detail"
            @touchstart="gtouchstart()"
            @touchmove="gtouchmove()"
            @touchend="showDeleteButton(toLink)"
          >
            <img :src="details.fromPlatform_logo" alt="" />
          </span>
        </div>
      </template>
    </goodsOptBox>
  </div>
</template>
<script setup>
import goodsOptBox from "./goodsOptBox.vue";
import { getCurrentInstance, ref, onMounted, computed } from "vue";
const { proxy } = getCurrentInstance();
const timeOutEvent = ref();
//----------------------------computed----------------------
const details = computed(() => {
  return proxy.$parent.details;
});
// 跳转到原平台商品界面
const toLink = () => {
  window.open(details.value.goodsUrlMobile);
};
//真正长按后应该执行的内容
const longPress = (val) => {
  timeOutEvent.value = 0;
  //执行长按要执行的内容，如弹出菜单
  navigator.clipboard.writeText(details.value.goodsUrlMobile);
  //   document.execCommand("details.value.fromUrl");
  proxy.$message.primary(proxy.$fanyi("链接已复制"));
};
const gtouchstart = () => {
  timeOutEvent.value = setTimeout(() => {
    longPress();
  }, 500); //这里设置定时器，定义长按500毫秒触发长按事件
  return false;
};
//手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
const showDeleteButton = (item) => {
  clearTimeout(timeOutEvent.value); //清除定时器
  if (timeOutEvent.value != 0) {
    // 执行点击事件
    // console.log("点击但未长按");
    item();
  }
  return false;
};
//如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
const gtouchmove = () => {
  clearTimeout(timeOutEvent.value); //清除定时器
  timeOutEvent.value = 0;
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.moduleTitle {
  display: flex;
  align-items: center;
  .name {
    flex: 0 0 200px;
    font-weight: 600;
    font-size: 28px;
  }
  .detail {
    font-size: 28px;
    width: 404px;
    font-weight: 400;
    color: #969595;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    img {
      height: 50px;
      width: auto;
    }
  }
}
</style>
