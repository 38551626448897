<template>
  <div class="backTopBtn" @click="toTop" style="display: none">
    <img :src="require('@/assets/icon/toTop.png')" alt="" />
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
window.addEventListener("scroll", function (e) {
  if (["ProductDetails", "CommoditySearch"].includes(proxy.$route.name)) {
    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
    let ele = document.getElementsByClassName("backTopBtn")[0];
    if (osTop > 2400) {
      ele.style.display = "flex";
    } else {
      ele.style.display = "none";
    }
  }
});
//------------------------ methods -------------------------------------
const toTop = () => {
  let timer = setInterval(function () {
    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
    let ispeed = Math.floor(-osTop / 60);
    document.documentElement.scrollTop = document.body.scrollTop =
      osTop + ispeed;
    this.isTop = true;
    if (osTop === 0) {
      clearInterval(timer);
    }
  }, 2);
};
//------------------------ pageLoad -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.backTopBtn {
  position: fixed;
  z-index: 3;
  width: 103px;
  height: 103px;
  right: 18px;
  bottom: 155px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
