<!-- 选择商品属性弹窗模块 -->
<template>
  <div>
    <goodsOptBox ref="goodsOptBox" :boxName="$fanyi('商品变体')">
      <template #modelName>
        <div class="moduleTitle">
          <span class="name">{{ $fanyi("商品规格属性") }}</span>
          <span class="detail">{{ selectedParameters }}</span>
        </div>
      </template>
      <div class="modelBox">
        <div class="forHead">
          <!-- 商品标题 -->
          <div class="boxCon">
            <div class="sheetTitle">
              {{ $fanyi("规格选择") }}
            </div>
          </div>
          <!-- 商品标题 -->
          <div class="boxCon">
            <div class="goodsInfoBox">
              <van-image
                class="goodsImg"
                :src="theMainFigure"
                @click="$lookImg(theMainFigure)"
                lazy-load
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div>
                <div class="goodsTitle">
                  {{ details.titleT }}
                </div>
                <div class="goodsPrice">
                  {{ $fun.RMBNumSegmentation(purchaseParameters.price) }}元({{
                    $fun.JPYNumSegmentation(
                      $fun.roundNumber(
                        purchaseParameters.price * $store.state.exchangeRate
                      )
                    )
                  }}円)
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 商品属性 -->
        <div class="boxCon">
          <div class="goodsDetailBox">
            <div
              class="goodsDetail"
              v-for="(detailsItem, detailsIndex) in details.goodsInfo
                ? details.goodsInfo.specification
                : []"
              :key="'detailsItem' + detailsIndex"
            >
              <!-- 2023/7/14 说要属性名称是日文,属性值是中文 -->
              <label class="detailName"> {{ detailsItem.keyT }} </label>
              <div class="detailOptBox">
                <div class="detailOptCon">
                  <div
                    class="detailCon"
                    v-for="(valueItem, valueIndex) in detailsItem.valueC"
                    :key="'valueItem' + valueIndex"
                    :class="{ activeDetail: detailsItem.chiose == valueIndex }"
                    @click="
                      detailsItem.chiose = valueIndex;
                      selectTheMainImage(valueItem.picUrl);
                      $parent.selectParameters();
                    "
                  >
                    <van-image
                      v-if="valueItem.picUrl"
                      class="goodsImg"
                      :src="valueItem.picUrl"
                      lazy-load
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>

                    {{ valueItem.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 库存数 -->
        <div class="boxCon">
          <div class="buyNum">
            <span>{{ $fanyi("库存数") }}:</span>
            <span
              >{{ purchaseParameters.amountOnSale }} {{ $fanyi("件") }}</span
            >
          </div>
        </div>
        <!-- 购买数量 -->
        <div class="boxCon">
          <div class="buyNum">
            <span>{{ $fanyi("选择数量") }}:</span>
            <van-stepper
              :min="1"
              integer
              id="steepp"
              :max="purchaseParameters.amountOnSale"
              @change="$parent.matchOfQuantity"
              @focus="stepperSelect"
              v-model.number="$parent.quantityPurchased"
            />
          </div>
        </div>

        <!-- 小计 -->
        <div class="boxCon">
          <div class="subTotalBox buyNum">
            <span>{{ $fanyi("小计") }}</span>
            <span class="priceBox"
              >{{ $fun.RMBNumSegmentation(theTotalPrice) }}元 ({{
                $fun.JPYNumSegmentation(
                  $fun.roundNumber(theTotalPrice * $store.state.exchangeRate)
                )
              }}円)</span
            >
          </div>
        </div>
        <!-- 添加购物车按钮 -->
        <div class="bottomDiv">
          <button class="addToCartButton" @click="$parent.addToShoppingCart">
            <img id="addCart" style="display: none" alt="" />{{
              $fanyi("加入购物车")
            }}
          </button>
        </div>
      </div>
    </goodsOptBox>
  </div>
</template>
<script>
export default {
  name: "commodityProperty",
};
</script>
<script setup>
import goodsOptBox from "./goodsOptBox.vue";
import { getCurrentInstance, ref, onMounted, computed } from "vue";
const { proxy } = getCurrentInstance();
//---------------------------------------data-------------------------
const num = ref(0);
//---------------------------------------computed-------------------------

const details = computed(() => {
  return proxy.$parent.details;
});
const goodsInfo = computed(() => {
  return proxy.$parent.goodsInfo;
});
const theMainFigure = computed(() => {
  return proxy.$parent.theMainFigure;
});
const theTotalPrice = computed(() => {
  return proxy.$parent.theTotalPrice || 0;
});
// 支付的参数
const purchaseParameters = computed(() => {
  return proxy.$parent.purchaseParameters;
});
const selectedParameters = computed(() => {
  // 假如父组件已获取到商品详情数据就循环;
  if (proxy.$parent.details.goodsInfo) {
    let specification = proxy.$parent.details.goodsInfo.specification;
    let theReturnedString = "";
    specification.forEach((specificationItem) => {
      theReturnedString +=
        specificationItem.valueC[
          specificationItem.chiose ? specificationItem.chiose : 0
        ].name + "/";
    });
    return theReturnedString.substr(0, theReturnedString.length - 1);
  } else {
    return "";
  }
});
//---------------------------------------methods-------------------------
const stepperSelect = (e) => {
  var input = document
    .querySelector("#steepp")
    .getElementsByClassName("van-stepper__input")[0]; //获取dom对象
  var value = input.value; //获取input的值
  input.focus(); //获得焦点
  input.setSelectionRange(0, value.length); //设置选中全部文本
};
// 如果选项有图片就更换为这张图片
const selectTheMainImage = (img) => {
  if (img) {
    proxy.$parent.theMainFigure = img;
  }
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.moduleTitle {
  display: flex;
  align-items: center;

  .name {
    flex: 0 0 200px;
    font-weight: 600;
    font-size: 28px;
  }
  .detail {
    font-size: 28px;
    width: 404px;
    font-weight: 400;
    color: #969595;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.modelBox {
  .forHead {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .boxCon {
    margin: 0 30px;
    .sheetTitle {
      line-height: 1;
      padding: 45px 0 40px;
      text-align: center;
      font-size: 30px;
    }
    .goodsInfoBox {
      display: flex;
      padding-bottom: 30px;
      margin-bottom: 2px;
      .goodsTitle {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 28px;
      }
      .goodsPrice {
        font-size: 30px;
        font-weight: 600;
        color: #b4272b;
      }
      .goodsImg {
        flex: 0 0 120px;
        height: 120px;
        margin-right: 30px;
        border-radius: 6px;
      }
    }
    .goodsDetailBox {
      overflow: hidden;
      .goodsDetail {
        font-size: 28px;
        border-top: #dfdfdf solid 1px;
        padding: 30px 0;
        .detailName {
          display: flex;
          font-size: 28px;
          align-items: center;
          min-width: 75px;
          font-weight: 400;
          color: #7b7b7b;
        }
        .detailOptBox {
          .detailOptCon {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .detailCon {
              flex: 0;
              margin-top: 30px;
              margin-right: 20px;
              padding: 0 25px;
              min-height: 53px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #f2f2f2;
              border-radius: 6px;
              white-space: nowrap;
              margin-right: 20px;
              .goodsImg {
                flex: 0 0 40px;
                height: 40px;
                margin-right: 15px;
              }
            }
            .detailCon.activeDetail {
              background: #b4272b;
              color: white;
            }
          }
        }
      }
    }

    .buyNum {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      border-top: 1px solid #dfdfdf;
      span {
        font-size: 28px;
        font-weight: 400;
        color: #7b7b7b;
        line-height: 1;
      }
    }
    .subTotalBox {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 28px;
        color: #999;
      }
      .priceBox {
        font-size: 30px;
        font-weight: 600;
        color: #b4272b;
      }
    }
    .addToCartButton {
      width: 690px;
      height: 70px;
      background: #b4272b;
      font-size: 28px;
      border-radius: 6px;
      // padding: 0 22px;
      color: white;

      img {
        height: 100%;
        margin: 0 auto;
      }
    }
  }
  .bottomBox {
    background-color: white;
    position: fixed;
    padding: 15px 0 50px;
    overflow: hidden;
    border: none;
    bottom: 0px;
  }
}
.bottomDiv {
  background-color: white;
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 50px;
  bottom: -1px;
  .addToCartButton {
    width: 690px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    color: white;
    font-size: 28px;
    font-weight: 400;
    img {
      height: 104%;
      margin: 0 auto;
    }
  }
}
</style>
