<!-- 商品详情价格和标题 -->
<template>
  <div
    class="priceGroup"
    v-if="details.goodsInfo && details.goodsInfo.priceRanges"
  >
    <div
      class="priceCon"
      v-if="
        details.goodsInfo.priceRanges.length &&
        details.goodsInfo.priceRanges[0].priceMin
      "
    >
      <div
        class="priceConOpt"
        v-for="(priitem, priindex) in details.goodsInfo.priceRanges"
        :key="priindex"
      >
        <p class="showPrice">
          <span class="price EUR"
            >{{ $fun.RMBNumSegmentation(priitem.priceMin) }}元</span
          >
          <span class="price">
            ({{
              $fun.JPYNumSegmentation(
                $fun.roundNumber(priitem.priceMin * $store.state.exchangeRate)
              )
            }}円)
          </span>
          <span v-show="priitem.priceMax != priitem.priceMin"
            >~
            <span class="price EUR"
              >{{ $fun.RMBNumSegmentation(priitem.priceMax) }}元</span
            >

            <span class="price">
              ({{
                $fun.JPYNumSegmentation(
                  $fun.roundNumber(priitem.priceMax * $store.state.exchangeRate)
                )
              }}円)
            </span>
          </span>
        </p>
        <p class="minimumShipmentQuantity">
          ≥
          {{ priitem.startQuantity }} {{ goodsInfo.unit || "PCS" }}
        </p>
      </div>
    </div>
    <div class="priceCon" v-else>
      <div
        class="priceConOpt"
        v-for="(ote, oin) in goodsInfo.priceRanges"
        :key="oin"
      >
        <p class="showPrice">
          <span class="price">
            {{ $fun.JPYNumSegmentation(ote.price) }}
          </span>
          元 (
          <span class="price">
            {{
              $fun.RMBNumSegmentation(
                $fun.roundNumber(ote.price * $store.state.exchangeRate)
              )
            }}</span
          >円)
        </p>
        <p>≥ {{ ote.startQuantity }}{{ goodsInfo.unit || "PCS" }}</p>
      </div>
    </div>
    <div class="goodsTitle">
      <span class="icon1688" v-if="details.fromPlatform == '1688'">
        <img :src="require('@/assets/icon/icon1688.png')" alt="" /> </span
      >{{ details.titleT }}
    </div>
    <div class="freight_cost_of_goods">
      <span>{{ $fanyi("国内配送") }}:{{ details.address }}</span>
      <span>{{ $fanyi("中国国内运费预估") }}：{{ freightGoods }} 元から</span>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted, computed } from "vue";
const { proxy } = getCurrentInstance();
//----------------------------computed----------------------
const details = computed(() => {
  return proxy.$parent.details;
});

// 购买数量
const goodsInfo = computed(() => {
  return proxy.$parent.goodsInfo;
});

const freightGoods = ref(0);

// 获取跨境1688运费
const goodsFreightPredict = () => {
  proxy.$api
    .goodsFreightPredict({
      goods_id: proxy.$route.query.goods_id,
      shop_type: proxy.$route.query.fromPlatform,
    })
    .then((res) => {
      ////console.log('事件名',res)
      if (res.code != 0) return this.$message.error(this.$fanyi(res.data.msg));
      //接下来的操作
      freightGoods.value = proxy.$fun.checkParamsNullArray(res.data.freight)
        ? res.data.freight
        : 0;
    });
};
goodsFreightPredict();
</script>

<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.priceGroup {
  width: 685px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin: 15px auto 0;
  padding: 30px;
  .priceCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .priceConOpt {
      font-size: 24px;
      .showPrice {
        line-height: 25px;

        margin: 10px 0 13px;
        .price {
          font-size: 24px;
        }
        .EUR {
          font-size: 28px;
          font-weight: 600;
        }
      }
      .minimumShipmentQuantity {
        color: #ababab;
        font-size: 20px;
      }
      span {
        color: #b4272b;
      }
    }
  }
}
.goodsTitle {
  font-size: 32px;
  font-weight: 400;
  color: #272727;
  line-height: 42px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  .icon1688 {
    width: 76px;
    height: 30px;
    background: #ff4000;
    border-radius: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    img {
      width: 52px;
    }
  }
}
.freight_cost_of_goods {
  display: flex;
  justify-content: space-between;
  span {
    font-size: 26px;
    margin-top: 20px;
  }
}
</style>
