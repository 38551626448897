<!--商品详情商品规格模块 -->
<template>
  <div>
    <goodsOptBox ref="goodsOptBoxRef">
      <template #modelName>
        <div class="moduleTitle">
          <span class="name">{{ $fanyi("商品变体") }}</span>
          <span class="detail">
            <span
              v-for="(item, index) in details.goodsInfo.detail"
              :key="index"
            >
              {{ item.valueC }};
            </span>
          </span>
        </div>
      </template>
      <div class="goodsDetail">
        <div class="forHead">
          <!-- 商品标题 -->
          <div class="boxCon">
            <div class="sheetTitle">
              {{ $fanyi("商品规格") }}
            </div>
          </div>
        </div>

        <div class="detailCon">
          <div
            class="detailOpt"
            v-for="(optItem, optIndex) in details.goodsInfo.detail"
            :key="optIndex"
          >
            <label>{{ optItem.keyT ? optItem.keyT : "" }}</label
            ><span>{{ optItem.valueC ? optItem.valueC : "" }}</span>
          </div>
        </div>
        <div class="bottomDiv">
          <button
            class="closeButton"
            @click="$refs.goodsOptBoxRef.show = false"
          >
            {{ $fanyi("确定") }}
          </button>
        </div>
      </div>
    </goodsOptBox>
  </div>
</template>
<script>
export default {
  name: "propertyOfGoods",
};
</script>
<script setup>
import goodsOptBox from "./goodsOptBox.vue";
import {getCurrentInstance, computed} from "vue";
const { proxy } = getCurrentInstance();
//----------------------------computed----------------------
const details = computed(() => {
  return proxy.$parent.details;
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.moduleTitle {
  display: flex;
  align-items: center;

  .name {
    flex: 0 0 200px;
    font-weight: 600;
    font-size: 28px;
  }
  .detail {
    width: 391px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
    // display: inline-flex;
    // align-items: center;
    span {
      line-height: 1;
      font-size: 28px;
      width: 404px;
      font-weight: 400;
      color: #969595;
    }
  }
}
.content {
  padding-bottom: 0 !important;
}
.goodsDetail {
  padding: 0 34px;

  .detailCon {
    .detailOpt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;
      border-bottom: solid 1px #dfdfdf;
      label {
        display: inline-block;
        flex: 0 0 100px;
        font-size: 24px;
        line-height: 1.2;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #999999;
        margin-right: 60px;
      }
      span {
        flex: 1;
        font-size: 24px;
      }
    }
  }
}
.forHead {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}
.sheetTitle {
  line-height: 1;
  padding: 45px 0 40px;
  text-align: center;
  font-size: 30px;
}
.bottomBox {
  background-color: white;
  position: fixed;
  padding: 15px 0 50px;
  overflow: hidden;
  bottom: 0px;
  .closeButton {
    width: 690px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    padding: 22px;
    color: white;
    font-size: 28px;
  }
}
.bottomDiv {
  background-color: white;
  position: sticky;
  padding-top: 15px;
  padding-bottom: 50px;
  bottom: -1px;
  .closeButton {
    width: 690px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    color: white;
    font-size: 28px;
    font-weight: 400;
  }
}
</style>
