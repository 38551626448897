<template>
  <div>
    <goodsOptBox :doNotOpen="false">
      <template #modelName>
        <div
          class="moduleTitle"
          @click="
            $fun.routerToPage(
              `/shopGoods?${'shopId=' + details.shopId}&shopType=${
                details.fromPlatform
              }&shopName=${details.newshopName || details.shopName}`
            )
          "
        >
          <span class="name">{{ $fanyi("店铺名称") }}</span>
          <span class="detail">{{
            details.newshopName || details.shopName
          }}</span>
        </div>
      </template>
    </goodsOptBox>
  </div>
</template>
<script setup>
import goodsOptBox from "./goodsOptBox.vue";
import { getCurrentInstance, computed } from "vue";
const { proxy } = getCurrentInstance();
//----------------------------computed----------------------
const details = computed(() => {
  return proxy.$parent.details;
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.moduleTitle {
  display: flex;
  align-items: center;

  .name {
    flex: 0 0 200px;
    font-weight: 600;
    font-size: 28px;
  }
  .detail {
    font-size: 28px;
    width: 404px;
    font-weight: 400;
    color: #969595;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
