<template>
  <div
    class="clickBox"
    @click="
      openSheet();
      mefun();
    "
  >
    <div v-if="!hasModelName">
      {{ boxName }}
    </div>
    <slot v-else name="modelName"></slot>
    <div v-if="doNotOpen">
      <van-icon name="arrow" />
    </div>
  </div>
  <div v-if="doNotOpen">
    <van-action-sheet v-model:show="show" class="goodsOptStree">
      <div class="content">
        <slot></slot>
      </div>
    </van-action-sheet>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, computed } from "vue";
const { proxy } = getCurrentInstance();
//-------------------------------props----------------------------
const props = defineProps({
  boxName: {
    default: "",
  },
  doNotOpen: {
    default: true,
  },
  fun: {
    default: () => {},
  },
});
//-------------------------------data----------------------------
const show = ref(false);
//-------------------------------computed----------------------------
const hasModelName = computed(() => {
  return !!proxy.$slots.modelName;
});
//-------------------------------methods----------------------------
const mefun = () => {
  if (props.fun) {
    props.fun();
  }
};
const openSheet = () => {
  show.value = !show.value;
};
defineExpose({ show, openSheet });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.clickBox {
  border-radius: 20px 20px 0px 0px;
  width: 690px;
  height: 113px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  font-size: 28px;
  font-weight: 600;
  color: #222222;
  line-height: 39px;
}
.content {
  // max-height: 1600px;
}
.van-icon-arrow {
  font-weight: bold;
}
</style>
<style>
.goodsOptStree {
  border-radius: 20px 20px 0px 0px !important;
}
</style>
