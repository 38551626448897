<template>
  <div class="slideImgs">
    <van-swipe class="my-swipe" indicator-color="white" :loop="false">
      <van-swipe-item
        v-if="details.video"
        class="showVideo"
        @click="lookVideo(details.video)"
      >
        <div class="btns">
          <img :src="require('@/assets/icon/playBtn.png')" alt="" />
        </div>
        <van-image class="roundCastPicture" :src="details.images[0]" lazy-load>
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </van-swipe-item>
      <van-swipe-item v-for="(item, index) in details.images" :key="index">
        <van-image
          class="roundCastPicture"
          :src="item"
          @click="getBigImg(details.images, index)"
          lazy-load
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </van-swipe-item>
    </van-swipe>
    <div class="coBranding">
      <img :src="require('@/assets/icon/app1688row.png')" alt="" />
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted, computed } from "vue";
import { ImagePreview } from "vant";
const { proxy } = getCurrentInstance();

const details = computed(() => {
  return proxy.$parent.details;
});
const playVideo = computed(() => {
  if (this.$parent.details.video) {
    setTimeout(() => {
      let box = proxy.$refs.videoBox;
      box.play();
    }, 500);
  }
  return "";
});
const lookVideo = (vio) => {
  let toCodeVio = proxy.$fun.toCode(vio);
  // 这里必须要使用vue路由跳转页面,因为重新加载页面之后浏览器不会让你自动播放有声音的视频,需要用户有交互才可以自动播放有声音的视频
  proxy.$router.push(`/ProductDetails-watchVideo?url=${toCodeVio}`);
};
const getBigImg = (image, index) => {
  ImagePreview({
    images: image,
    showIndex: true,
    loop: false,
    startPosition: index,
  });
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.slideImgs {
  position: relative;
  margin-bottom: 20px;
  .bigVideo {
    width: 750px;
    height: 750px;
    pointer-events: none;
  }
  .showVideo {
    position: relative;
    .btns {
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      z-index: 3;
      width: 130px;
      height: 130px;
      transform: translate(-50%, -50%);
      background-color: rgba($color: #000000, $alpha: 0.67);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-left: 15px;
        width: 60px !important;
        height: 60px !important;
      }
    }
  }
  .my-swipe .van-swipe-item {
    color: #dcdfe6;
    font-size: 12px;
    line-height: 12px;
    text-align: center;

    .roundCastPicture {
      width: 750px;
      height: 750px;
    }

    img {
      width: 750px;
      height: 750px;
    }
  }

  :deep().van-swipe__indicator--active {
    //
    background-color: #b4272b !important;
  }
  .coBranding {
    margin: 20px 0;
    text-align: center;
    img {
      width: 690px;
    }
  }
}
</style>
