<template>
  <div
    class="productDetailsPage"
    :class="{ yanXuanDetail: $route.query.fromPlatform == 163 }"
    @scroll="scrollPage"
  >
    <!-- loading动画 -->
    <myLoading v-if="loading" />
    <div class="content" v-if="!!details.goodsInfo">
      <!-- 头部1 -->
      <div
        class="hideHeader"
        v-if="schedule < 1 || !schedule"
        :style="`opacity: ${1 - schedule / 100};`"
      >
        <div class="btnBox back" @click="$router.go(-1)">
          <img :src="require('@/assets/icon/fanhui.png')" alt="" />
        </div>
        <div class="dip btnList">
          <div class="btnBox" @click="copyUrl">
            <img
              @click="copyUrl"
              :src="require('@/assets/icon/pude_copy.svg')"
              alt=""
            />
          </div>
          <div class="btnBox" @click="$fun.routerToPage('cart')">
            <div
              class="haveNum"
              @click="$fun.routerToPage('cart')"
              v-if="dataCount && $store.state.userInfo"
            >
              {{ dataCount }}
            </div>
            <img :src="require('@/assets/icon/gouwuchebai.png')" alt="" />
          </div>
        </div>
      </div>
      <!-- 头部2 -->
      <headgoback
        class="headGundong"
        :style="`opacity: ${schedule / 100}; z-index:${schedule}`"
        v-if="schedule > 0.2"
      >
        <div class="headIcon" @click="$fun.routerToPage('cart')">
          <div class="haveNum" v-if="dataCount && $store.state.userInfo">
            {{ dataCount }}
          </div>
          <img
            @click="$fun.routerToPage('cart')"
            class="cartIcon"
            :src="require('@/assets/icon/gouwuche.png')"
            alt=""
          />
        </div>
      </headgoback>
      <!-- 商品图片轮播图 -->
      <picturesOfProducts />
      <!-- 价格 -->
      <priceBox />
      <!-- 商品详情 -->
      <CommodityProperty ref="commodityProperty" v-if="details.goodsInfo" />
      <!-- 商品属性 -->
      <PropertyOfGoods
        ref="propertyOfGoods"
        v-if="
          details.goodsInfo &&
          details.goodsInfo.detail &&
          details.goodsInfo.detail.length
        "
      />
      <!-- 店铺其他商品 -->
      <suppliers v-if="$route.query.fromPlatform == '1688'" />
      <!-- 商品来源界面 -->
      <commoditySource />
      <!-- 接口返回的商品介绍 -->
      <div class="coBrandingBanner">
        <img
          src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202401/6593c113d09e4.png"
          alt=""
        />
      </div>
      <div class="introductionOfGoods" id="introductionOfGoods">
        <!-- <div v-html="details.description"></div> -->
      </div>
      <div class="technicalSupport">
        {{ $fanyi("由1688提供部分技术服务⽀持") }}
      </div>
    </div>
    <!-- 没有商品展示图片 -->
    <thereAreNoGoods v-else v-show="!loading" />
    <backTop />
    <div class="pageBottom">
      <!-- 底部操作栏 -->
      <operation />
      <!-- <xuanfufoot></xuanfufoot> -->
    </div>
  </div>
</template>
<script setup>
import commoditySource from "./components/commoditySource.vue";
import headgoback from "../../components/headGoBack/index.vue";
import xuanfufoot from "@/components/footBar/index.vue";
import suppliers from "./components/suppliers.vue";
import priceBox from "./components/priceBox.vue";
import CommodityProperty from "./components/commodityProperty.vue";
import operation from "./components/operation.vue";
import PropertyOfGoods from "./components/propertyOfGoods.vue";
import picturesOfProducts from "./components/picturesOfProducts.vue";
import myLoading from "@/components/loading/index.vue";
import Dialog from "@/components/Dialog/index.vue";
import thereAreNoGoods from "./components/thereAreNoGoods.vue";
import { getCurrentInstance, ref, onMounted, computed } from "vue";
import backTop from "../../components/backTop/index.vue";
import md5 from "js-md5";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
// const token = ref(localStorage.getItem("user_token"));

const datas = ref({
  goods_id: proxy.$route.query.goods_id,
  shop_type: proxy.$route.query.fromPlatform,
});
const cantAdd = ref(false); //
const loading = ref(true);
const details = ref({}); //详情
const theTotalPrice = ref(0); //总价
const quantityPurchased = ref(1); //购买数量
const goodsInfo = ref({}); //商品属性
const purchaseParametersArr = ref([]); //购买参数数组
const goodsFavoriteStatus = ref(false); //商品是否已收藏
const lazyImgArr = ref(); //需要懒加载的图片数组
const purchaseParameters = ref({
  startQuantity: 1, //起拍价
  price: 0, //价格
  amountOnSale: 0, //在库数
}); //购买参数
const theMainFigure = ref(""); //主图,用来传入购物车使用
const schedule = ref(0.01); // 顶部转换进度
const dataCount = computed(() => {
  return proxy.$store.state.cartCount.dataCount;
});
//------------------------ pageLoad -------------------------------------
onMounted(() => {
  productDetails();
  document.addEventListener("scroll", scrollPage);
  // console.log(proxy.$fun.setUserID());
});
//------------------------ methods --------------------------------------
const scrollPage = (e) => {
  if (proxy.$route.name == "ProductDetails") {
    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
    let Max = 500;
    let min = 0;
    let kedu = (Max - min) / 100;
    let jindu = Math.max(Math.min(osTop / kedu, 100), 0); //滚动进度
    schedule.value = jindu;
  }
};
// 查看商品是否已收藏
const favoriteGoodsCheckIn = () => {
  if (!localStorage.getItem("japan_user_token")) {
    return false;
  }
  proxy.$api
    .favoriteGoodsCheckIn({
      shop_type: proxy.$route.query.fromPlatform,
      goods_id: JSON.parse(JSON.stringify([proxy.$route.query.goods_id])),
    })
    .then((res) => {
      if (res.code != 0) return false;
      goodsFavoriteStatus.value = res.data[0].inFavorite;
    });
};
// 添加收藏商品
const favoriteGoodsAdd = () => {
  if (!proxy.$store.state.userInfo) {
    proxy.$store.commit("changeshowLoginDia", !proxy.$store.state.showLoginDia);
  }
  let datas = {
    shop_type: proxy.$route.query.fromPlatform,
    goods_id: details.value.goodsId,
    title: details.value.titleT,
    image_url: details.value.images[0],
    price: details.value.goodsInfo.priceRanges[0]
      ? details.value.goodsInfo.priceRanges[0].priceMin
      : purchaseParameters.value.price, //这里取得是接口返回的所有价格区间第一个的最小值
  };
  if (!goodsFavoriteStatus.value) {
    proxy.$api.favoriteGoodsAdd(datas).then((res) => {
      if (res.code != 0) return;
      goodsFavoriteStatus.value = !goodsFavoriteStatus.value;
    });
  } else {
    let arr = [];
    arr.push({
      shop_type: details.value.fromPlatform,
      goods_id: details.value.goodsId,
    });
    proxy.$api.favoriteGoodsDelete({ delete: arr }).then((res) => {
      if (res.code != 0) return;
      goodsFavoriteStatus.value = !goodsFavoriteStatus.value;
    });
  }
};
// 懒加载图片
const lazyLoad = (imgs, num) => {
  // 页面加载时加载前面几张图片
  if (num) {
    for (let i = 0; i < num; i++) {
      if (imgs[i]) {
        imgs[i].src = imgs[i].getAttribute("datasrc");
      }
    }
    return;
  }
  // 视口的高度；
  const clientH = document.documentElement.clientHeight;
  // 滚动的距离，这里的逻辑判断是为了做兼容性处理；
  const clientT = document.documentElement.scrollTop || document.body.scrollTop;
  for (let i = 0; i < imgs.length; i++) {
    // 逻辑判断，如果视口高度+滚动距离 > 图片到浏览器顶部的距离就去加载；
    // !imgs[i].src 是避免重复请求，可以把该条件取消试试：可以看到不加该条件的话往回滚动就会重复请求；
    if (
      clientH + clientT > imgs[i].offsetTop &&
      imgs[i].src != imgs[i].getAttribute("datasrc")
    ) {
      // 使用data-xx的自定义属性可以通过dom元素的dataset.xx取得；
      // console.log(imgs[i].datasrc);
      imgs[i].src = imgs[i].getAttribute("datasrc");
    }
  }
};
// 处理商品介绍图片
const processPictures = (value) => {
  setTimeout(() => {
    let box = document.getElementById("introductionOfGoods");
    let dom = document.createElement("div");
    dom.innerHTML = JSON.parse(JSON.stringify(value));
    let imgArr = dom.getElementsByTagName("img");
    lazyImgArr.value = imgArr;
    //
    for (let i = 0; i < imgArr.length; i++) {
      let simgsrc = imgArr[i].src;
      imgArr[i].setAttribute("datasrc", simgsrc);
      // imgArr[i].src = require("../../assets/icon/delete.png");
      imgArr[i].src = "";
    }
    if (box) {
      box.appendChild(dom);
    }
    preload();
    document.addEventListener(
      "scroll",
      throttle(() => {
        if (proxy.$route.name == "ProductDetails") {
          lazyLoad(lazyImgArr.value);
        }
      }, 200)
    );
  }, 100);
};
// 预加载商品规格里面的图片
const preload = () => {
  let imgArr = [];
  // 获取所有商品规格的图片
  details.value.goodsInfo.specification.forEach((item1) => {
    item1.valueC.forEach((item2) => {
      if (!!item2.picUrl) {
        imgArr.push(item2.picUrl);
      }
    });
  });
  imgArr.forEach((imgItem) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", imgItem);
    xhr.send("");
  });
  setTimeout(() => {
    lazyLoad(lazyImgArr.value, 3);
  }, 100);
};
// 节流
const throttle = (fn, delay) => {
  let timer = null;
  return () => {
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      fn(lazyImgArr.value);
      timer = null;
    }, delay);
  };
};
// 获取商品详情
const productDetails = () => {
  let dateCode = Date.now(); //这里必须要单独设置参数,否则会获取不到商品详情
  let k = "wakanda forever!@9523`";
  let s = md5(`${k}${dateCode}${k}`);
  datas.value.sign = s;
  datas.value.timestamp = dateCode;
  datas.value.outMemberId = proxy.$fun.setUserID();
  proxy.$api.goodsParticulars(datas.value).then((res) => {
    loading.value = false;
    if (res.code != 0 || !res.data.goodsInfo) return;

    saleShopName(res.data.shopName);
    res.data.shopName = "";
    details.value = res.data;

    goodsInfo.value = res.data.goodsInfo;
    document.title = "Rakumart-" + res.data.titleT.substr(0, 50); //由于订单详情数据是跳转页面后再获取的，所以不能在路由跳转的时候修改
    favoriteGoodsCheckIn();
    dataProcessing();
    processPictures(res.data.description);
    setTimeout(() => {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    }, 300);
  });
};
// 获取店铺名称
const saleShopName = (in_shop_name) => {
  proxy.$api
    .saleShopName({
      shop_name: proxy.$route.query.shopName,
      shop_type: proxy.$route.query.fromPlatform,
      goods_id: proxy.$route.query.goods_id,
    })
    .then((res) => {
      if (res.code != 0) {
        details.value.shopName = in_shop_name;
        return;
      }
      details.value.shopName = res.data || in_shop_name;
    });
};
// 处理数据
const dataProcessing = () => {
  // 为所有商品属性设置一个初始选中
  // 商品主图规则
  // 如果商品的规格有图片就显示第一个商品规格的图片,否则设置为商品图片组的图片,商品规格默认选中第一项
  let imgUrl = "";
  goodsInfo.value.specification.forEach((specificationItem) => {
    specificationItem.chiose = 0;
    // 如果商品的规格有图片就把图片设为主图
    if (specificationItem.valueC[specificationItem.chiose].picUrl && !imgUrl) {
      imgUrl = specificationItem.valueC[specificationItem.chiose].picUrl;
    }
  });
  // 将主图设置为商品规格的图片或者展示商品图片组的第一张
  theMainFigure.value = imgUrl || details.value.images[0];
  selectParameters();
};
// 选择商品购买参数
const selectParameters = () => {
  let str = [];
  goodsInfo.value.specification.forEach((specificationItem) => {
    if (specificationItem.valueC) {
      str.push(specificationItem.valueC[specificationItem.chiose].name);
    }
  });
  let userChioseData = goodsInfo.value.goodsInventory.find((goodsInvIt) => {
    return (
      goodsInvIt.keyC == str.join("㊖㊎") || goodsInvIt.keyT == str.join("㊖㊎")
    );
  });
  purchaseParametersArr.value = userChioseData.valueC;
  // 重新匹配购买参数
  matchOfQuantity();
};
// 根据购买数量从购买参数数组里匹配购买参数
const matchOfQuantity = () => {
  // 在购买参数数组里从后往前匹配合适的购买参数
  let index = (() => {
    let num = 0;
    for (let i = purchaseParametersArr.value.length - 1; i >= 0; i--) {
      const purchaseParametersArrItem = purchaseParametersArr.value[i];
      if (quantityPurchased.value >= purchaseParametersArrItem.startQuantity) {
        num = i;
        break;
      }
    }
    return num;
  })();
  purchaseParameters.value = purchaseParametersArr.value[index];
  // 重新计算总价;
  gettheTotalPrice();
};
// 计算总价
const gettheTotalPrice = () => {
  // 根据购买参数计算总价(人民币)
  theTotalPrice.value = proxy.$fun.ceil(
    quantityPurchased.value * purchaseParameters.value.price
  );
};
// 添加购物车
const addToShoppingCart = () => {
  let japan_user_token = localStorage.getItem("japan_user_token");
  // 未登录时打开登录弹窗
  if (!proxy.$store.state.userInfo && japan_user_token == null) {
    proxy.$store.commit("changeshowLoginDia", !proxy.$store.state.showLoginDia);
    return;
  }
  // 因为商品属性和商品详情里面也是一个组件所以需要取里面的组件的值
  let openDetailModel = proxy.$refs.commodityProperty.$refs.goodsOptBox.show;
  if (openDetailModel) {
    if (quantityPurchased.value == 0) {
      return proxy.$message.warning("请选择商品数量");
    }
    if (cantAdd.value) {
      return;
    } else {
      cantAdd.value = true;
    }
    let detailChinese = (() => {
      let data = [];
      details.value.goodsInfo.specification.forEach((specificationItem) => {
        data.push({
          key: specificationItem.keyT,
          value: specificationItem.valueC[specificationItem.chiose].name,
        });
      });
      return data;
    })();
    let recommendedGoods = proxy.$route.query.recommendedGoods;
    let source = proxy.$route.query.source;
    if (proxy.$route.query.recommendedGoods == "undefined") {
      recommendedGoods = undefined;
    }
    if (proxy.$route.query.source == "undefined") {
      source = undefined;
    }
    let traceInfo = {
      isJxhy: recommendedGoods,
      type: source,
    };

    if (proxy.$route.query.source && proxy.$route.query.recommendedGoods) {
      if (
        proxy.$route.query.recommendedGoods == "true" &&
        proxy.$route.query.source == "index"
      ) {
        if (proxy.$fun.isOfficialWeb()) {
          __bl.sum("首页爆品商品加入到购物车统计");
        }
      }
      if (
        proxy.$route.query.recommendedGoods == "true" &&
        proxy.$route.query.source != "index"
      ) {
        if (proxy.$fun.isOfficialWeb()) {
          __bl.sum("关键词与图搜爆品商品加入到购物车统计");
        }
      }
    }

    let data = {
      goods_id: details.value.goodsId,
      goods_title: details.value.titleT,
      price: purchaseParameters.value.price,
      num: quantityPurchased.value,
      pic: theMainFigure.value, //提交设置的主图参数
      detail: JSON.stringify(detailChinese),
      sku_id: purchaseParameters.value.skuId,
      spec_id: purchaseParameters.value.specId,
      shop_id: details.value.shopId,
      from_platform: details.value.fromPlatform,
      shop_name: details.value.shopName,
      price_ranges: JSON.stringify(details.value.goodsInfo.priceRanges || []),
      trace: recommendedGoods && source ? JSON.stringify(traceInfo) : undefined,
    };
    // console.log(details.value.goodsInfo);

    proxy.$api
      .goodsToCart({
        to_cart: [data],
      })
      .then((res) => {
        if (res.code != 0) {
          cantAdd.value = false;
          return proxy.$message.error(proxy.$fanyi(res.msg));
        }
        //接下来的操作
        let img = document.querySelector("#addCart");
        img.src = require("../../assets/cart/cart.gif");
        img.style.display = "block";
        proxy.$message.success(proxy.$fanyi("操作成功"));
        setTimeout(() => {
          img.src = "";
          img.style.display = "none";
          cantAdd.value = false;
          proxy.$refs.commodityProperty.$refs.goodsOptBox.show = false; // 2023/4/28 加入购物车成功之后需要关闭选择规格弹窗
        }, 2000);
        proxy.$store.dispatch("goodsToCartNum");
        return;
      })
      .catch(() => {
        cantAdd.value = false;
      });
  } else {
    // 这里如果想要打开商品属性弹窗需要调用里面goodsOptBox的openSheet事件
    proxy.$refs.commodityProperty.$refs.goodsOptBox.openSheet();
  }
};
// 复制商品链接
const copyUrl = () => {
  navigator.clipboard.writeText(details.value.goodsUrlMobile);
  proxy.$message.primary(proxy.$fanyi("复制成功"));
};
defineExpose({
  datas,
  loading,
  details,
  theTotalPrice,
  quantityPurchased,
  goodsInfo,
  purchaseParametersArr,
  purchaseParameters,
  theMainFigure,
  favoriteGoodsCheckIn,
  favoriteGoodsAdd,
  productDetails,
  dataProcessing,
  selectParameters,
  matchOfQuantity,
  gettheTotalPrice,
  addToShoppingCart,
  copyUrl,
  goodsFavoriteStatus,
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.haveNum {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0.48rem;
  padding: 0 0.10667rem;
  height: 0.48rem;
  background-color: #ff4437;
  border-radius: 0.24rem;
  position: absolute;
  top: -10px;
  right: -10px;
  text-align: center;
  line-height: 0.48rem;
  font-size: 0.32rem;
  color: #fff;
  border: 0.04rem solid #fff;
}
.productDetailsPage {
  background-color: #f6f6f6;
  min-height: 100vh;
  // overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .hideHeader {
    width: 750px;
    height: 98px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    padding: 0 20px;
    z-index: 3;
    .btnList {
      gap: 15px;
    }
    .btnBox {
      width: 60px;
      height: 60px;
      background: rgba($color: #000000, $alpha: 0.4);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 5;
      img {
        width: auto;
        height: 30px;
      }
    }
    .btnBox.back {
      img {
        margin-right: 3px;
      }
    }
  }
  :deep().headGundong {
    position: fixed;
    top: 0;
  }
  .headIcon {
    @extend .dip;
    width: 60px;
    margin-left: auto;
    .addToFavorites {
      margin-right: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 35px;
        height: 33px;
      }
    }
    .haveNum {
      right: 10px;
      top: 20px;
    }
    .cartIcon {
      width: 30px;
      height: 30px;
    }
  }
  .content {
    .coBrandingBanner {
      width: 100%;
      text-align: center;
      img {
        width: 690px;
      }
    }
    // 商品详情介绍css
    .introductionOfGoods {
      background-color: white;
      margin-bottom: 160px;
    }

    /deep/.introductionOfGoods {
      margin: 20px 30px 30px;
      font-size: unset;

      span {
        font-size: unset;
      }

      p {
        width: 100% !important;
        display: block;
      }

      img {
        max-width: 750px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      table {
        width: 100% !important;
        max-width: 100vw !important;
        height: 100% !important;
        table-layout: fixed !important;
        word-break: break-all !important;
      }

      div > table {
        width: 100% !important;
        max-width: 100vw !important;
        height: 100% !important;
        overflow: hidden !important;
      }

      div {
        width: 100% !important;
        max-width: 100vw !important;
        height: 100% !important;
        overflow: hidden !important;
        position: unset !important;
      }
    }
    .technicalSupport {
      margin: 30px 0 30px;
      font-size: 20px;
      color: #999999;
      line-height: 1;
      text-align: center;
    }
  }

  // 这里设置粘性定位是因为要放底部栏,组件里也放了以防万一
  .pageBottom {
    position: sticky;
    bottom: 0;
    width: 100%;
    :deep().tabBar {
      z-index: 3000;
    }
  }
}
.yanXuanDetail {
  :deep() p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -6px;
  }
}
</style>
